.fmc-tracker {
  --dot-size: 5.5rem;
  --dot-outline-color: var(--fds-color--gray2);
  --dot-outline-color-active: transparent;
  --dot-highlight-color: transparent;
  --dot-highlight-offset: 0.5rem;
  --dot-text-color: var(--fds-color--gray3);
  --dot-text-color-active: var(--fds-color--white);
  --dot-color-success: var(--fds-color--success1);
  --dot-color-active: var(--fds-color--primary);
  --cursor: pointer;
  --shadow: none;
  --line-weight: 0.1rem;
  --line-weight-complete: 0.3rem;
  --line-color: var(--fds-color--gray2);
  --line-color-complete: var(--fds-color--primary);
  --line-animation-width: 0%;

  @include fds-set-brand(lincoln) {
    --dot-outline-color: var(--fds-color--gray3);
    --dot-color-success: var(--fds-color--secondary);
    --dot-color-active: transparent;
    --dot-outline-color-active: var(--fds-color--secondary);
    --dot-text-color-active: var(--fds-color--gray3);
    --line-color: var(--fds-color--gray3);
    --line-weight-complete: 0.1rem;
  }

  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  list-style-type: none;

  &.fmc-tracker--simple {
    --dot-size: 2.8rem;
    --dot-highlight-offset: 0.4rem;
  }

  .fmc-tracker__step {
    position: relative;
    width: 100%;
    min-width: calc(var(--dot-size) + 10rem);
    max-width: calc(var(--dot-size) + 20rem);

    &::before,
    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 50%;
      right: 0;
      bottom: 0;
      width: 100%;
    }

    &::before {
      top: calc((var(--dot-size) - var(--line-weight)) / 2);
      height: var(--line-weight);
      background-color: var(--line-color);
    }

    &::after {
      width: 100%;
      transform: scaleX(var(--line-animation-width));
      transform-origin: left center;
      top: calc((var(--dot-size) - var(--line-weight-complete)) / 2);
      height: var(--line-weight-complete);
      background-color: var(--line-color-complete);
      transition: transform 0.3s ease-in-out;
    }

    &:last-child {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  .fmc-tracker__step--complete {
    --line-animation-width: 100%;
  }

  .fmc-tracker__dot {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: var(--dot-size);
    height: var(--dot-size);
    background-color: var(--fds-color--white);
    color: var(--dot-text-color);
    border-radius: 100%;
    z-index: 1;
    transition: color 0.3s ease-in-out;
    cursor: var(--cursor);
    font-size: 1.2rem;
    margin: 0 auto;

    &:focus-visible {
      outline: var(--fds-outline);
      outline-offset: 0.5rem;
    }

    &::after {
      content: '';
      position: absolute;
      top: var(--dot-highlight-offset);
      left: var(--dot-highlight-offset);
      right: var(--dot-highlight-offset);
      bottom: var(--dot-highlight-offset);
      border: 1px solid var(--dot-outline-color);
      background-color: var(--dot-highlight-color);
      border-radius: 100%;
      box-shadow: var(--shadow);
      z-index: -1;
      transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    }

    @include fds-set-hover {
      --dot-highlight-color: var(--fds-color--secondary);
      --dot-text-color: var(--fds-color--white);
      --shadow: #{$fds-elevation__box-shadow--layer2};
      --dot-outline-color: transparent;
    }

    @include fds-set-disabled {
      --dot-highlight-color: var(--fds-color--disabled3);
      --dot-text-color: var(--fds-color--white);
      --dot-outline-color: transparent;
      --cursor: not-allowed;
      --shadow: none;
    }
  }

  .fmc-tracker__dot--active {
    --dot-highlight-color: var(--dot-color-active);
    --dot-text-color: var(--dot-text-color-active);
    --dot-outline-color: var(--dot-outline-color-active);
  }

  .fmc-tracker__dot--success {
    --dot-highlight-color: var(--dot-color-success);
    --dot-text-color: var(--fds-color--white);
    --dot-outline-color: transparent;
  }

  .fmc-tracker__title {
    padding: 0 1rem;
    text-align: center;
    overflow-wrap: break-word;
  }
}
