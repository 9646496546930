.fds-popup-tooltip {
  position: relative;
  display: inline-block;

  &.fds-tooltip .fds-tooltip__wrapper {
    width: fds-rem(300px);
    height: auto;
    white-space: normal;
  }

  & .fds-tooltip__content-wrapper {
    max-height: fds-rem(668px);
  }

  & .fds-tooltip__content {
    width: fds-rem(268px);
  }

  & .fds-popup-tooltip-close {
    position: absolute;
    right: fds-rem(2px);
    top: fds-rem(2px);
  }
}

.fds-popup-tooltip-takeover,
.fds-popup-tooltip {
  & .fds-tooltip__content-wrapper {
    @extend %fmc-type--body1;
    position: relative;
  }

  & .fds-popup-tooltip__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: fds-rem(10px);
    line-height: fds-rem(26px);
  }

  & .fds-tooltip__content {
    overflow-y: auto;
    line-height: fds-rem(26px);
  }

  & .fds-popup-tooltip-close {
    position: absolute;
    right: fds-rem(2px);
    top: fds-rem(2px);
  }

  & .fds-popup-tooltip__focusable-element--focused {
    outline: 1px solid var(--fds-color--primary);

    &.fds-tooltip__content {
      outline-offset: -1px;
    }

    &.fds-popup-tooltip-close {
      outline-offset: 1px;
    }
  }
}

.fds-popup-tooltip-takeover {
  position: fixed;
  top: 0;
  left: 0;
  right: fds-rem(9999px);
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  opacity: 0;
  z-index: 102;
  padding: fds-rem(15px);
  background-color: var(--fds-color--white);
  transform: scale(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  & .fds-tooltip__wrapper {
    position: relative;
  }

  & .fds-popup-tooltip-close {
    @include media('<=fds-md') {
      font-size: fds-rem(24px);
      position: absolute;
      right: 0;
    }
  }

  &.fds-popup-tooltip-takeover--show {
    transform: scale(1);
    opacity: 1;
    right: 0 !important;
  }

  & .fds-tooltip__content {
    width: auto;
  }
}
