.fmc-toggle {
  --toggle-label-color: var(--fds-color--gray3);
  --toggle-text-color-outside: var(--fds-color--gray3);
  --toggle-text-color-in-track: var(--fds-color--white);
  --toggle-option-font-size: #{fds-rem(11px)};
  --toggle-width: #{fds-rem(80px)};
  --toggle-height: #{fds-rem(40px)};
  --track-color--checked: var(--fds-color--secondary);
  --track-color--unchecked: var(--fds-color--gray2);
  --track-color--disabled: var(--fds-color--disabled3);
  --track-color: var(--track-color--unchecked);
  --slider-diameter: #{fds-rem(32px)};
  --slider-color: var(--fds-color--white);
  --slider-color-disabled: var(--fds-color--disabled1);
  --radius: var(--toggle-height);
  --slider-margin: calc((var(--toggle-height) - var(--slider-diameter)) / 2);
  --slider-translate--checked: calc(((var(--toggle-width) - var(--slider-diameter)) / 2) - var(--slider-margin));
  --slider-translate--unchecked: calc(0px - var(--slider-translate--checked));
  --slider-translate: var(--slider-translate--unchecked);
  --transition-time: 0.3s;

  // Not sure if shadow is desired
  --track-shadow: #{
    fds-rem(0 1px 0 -1px) #fff,
    fds-rem(0 -5px 10px 2px) #fff,
    fds-rem(0 -5px 10px 2px) #fff,
    fds-rem(0 10px 10px 0) rgba(0, 0, 0, 0.2),
    inset fds-rem(0 4px 7px 0) rgba(47, 47, 47, 0.35),
    inset fds-rem(0 -2px 4px 0) #ababab
    };
  --slider-shadow: #{fds-rem(0 5px 5px 0) rgba(0, 0, 0, 0.1), fds-rem(0 5px 5px 0) rgba(0, 0, 0, 0.2)};


  @include fds-set-brand(lincoln) {
    --toggle-option-font-size: #{fds-rem(13px)};
    --toggle-width: #{fds-rem(50px)};
    --toggle-height: #{fds-rem(26px)};
    --slider-diameter: #{fds-rem(20px)};
    --track-shadow: none; // Lincoln version is not skeuomorphic
    --slider-shadow: none;
    --toggle-text-color-outside: var(--fds-color--gray4); // NOTE: maybe different label text; probably primary.
    --track-color--unchecked: var(--fds-color--gray4);
    --track-color--checked: var(--fds-color--primary);
    --track-color--disabled: var(--fds-color--gray4);
    --slider-color-disabled: var(--fds-color--disabled2);

    line-height: 1.25;
  }

  &.fmc-toggle--skeuomorphic {
    .fmc-toggle__track {
      box-shadow: var(--track-shadow);
    }

    .fmc-toggle__track::before {
      box-shadow: var(--slider-shadow);
    }
  }

  :hover {
    cursor: pointer;
  }

  @include fds-set-disabled {
    --track-color--unchecked: var(--track-color--disabled);
    --track-color--checked: var(--track-color--disabled);
    --slider-color: var(--slider-color-disabled);
    --track-shadow: none;

    .fmc-toggle__unchecked-option,
    .fmc-toggle__checked-option {
      // Using 'visibilbity: hidden' over 'display: none' so we don't disturb the layout
      visibility: hidden;
    }
    cursor: not-allowed;

    span, input {
      cursor: not-allowed;
    }
  }

  display: inline-flex;
  align-items: center;

  .fmc-toggle__label {
    margin-right: fds-rem(40px);
    color: var(--toggle-label-color);
  }

  .fmc-toggle__unchecked-option,
  .fmc-toggle__checked-option {
    font-size: var(--toggle-option-font-size);
  }

  & > .fmc-toggle__unchecked-option {
    // Immediate child combinator (">") here and on the next is important.
    margin-right: fds-rem(16px);
    color: var(--toggle-text-color-outside);
  }

  & > .fmc-toggle__checked-option {
    margin-left: fds-rem(16px);
    color: var(--toggle-text-color-outside);
  }

  input {
    width: 0;
    margin: 0;
    opacity: 0;
  }

  .fmc-toggle__track {
    position: relative;

    width: var(--toggle-width);
    height: var(--toggle-height);
    background-color: var(--track-color);
    transition: background-color var(--transition-time);
    border-radius: var(--radius);
    margin: 0;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex: none;

    & > .fmc-toggle__unchecked-option,
    & > .fmc-toggle__checked-option
    {
      color: var(--toggle-text-color-in-track);
      width: var(--slider-diameter);
      justify-content: center;
    }

    & > .fmc-toggle__unchecked-option {
      margin-left: var(--slider-diameter);
      display: inline-flex;
    }

    & > .fmc-toggle__checked-option {
      margin-right: var(--slider-diameter);
      display: none;
    }
  }

  .fmc-toggle__track::before {
    // track::before is the slider
    position: absolute;
    content: "";
    width: var(--slider-diameter);
    height: var(--slider-diameter);
    border-radius: var(--radius);
    background-color: var(--slider-color);
    transform: translateX(var(--slider-translate));
    transition: transform var(--transition-time);
  }

  input:checked + .fmc-toggle__track {
    --track-color: var(--track-color--checked);
    --slider-translate: var(--slider-translate--checked);

    & > .fmc-toggle__unchecked-option {
      display: none;
    }

    & > .fmc-toggle__checked-option {
      display: inline-flex;
    }
  }

  input:focus-visible + .fmc-toggle__track {
    outline: var(--fds-outline);
    outline-offset: fds-rem(8px);
  }
}
