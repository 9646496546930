.fmc-top-banner {
  --alert-height: #{fds-rem(8px)};
  --alert-color: var(--fds-color--primary);
  --icon-color: var(--alert-color);
  --tb-icon-size: #{fds-rem(24px)};
  --dismiss-icon-size: #{fds-rem(24px)};
  --font-size: #{fds-rem(16px)};
  --icon-offset: #{fds-rem(16px)};
  --shadow: #{$fds-elevation__box-shadow--layer3};
  --icon-symbol--default: var(--fds-font--ford-icons__info-filled);
  --icon-symbol--warning: var(--fds-font--ford-icons__warning-filled);
  --icon-symbol--success: var(--fds-font--ford-icons__check-filled);
  --icon-symbol--error: var(--fds-font--ford-icons__warning-filled);
  --icon-symbol: none;
  --border-bottom: var(--alert-height) solid var(--alert-color);
  --text-color: var(--fds-color--primary);
  --text-color--default: var(--fds-color--primary);
  --text-color--success: var(--fds-color--primary);
  --text-color--warning: var(--fds-color--primary);
  --text-color--error: var(--fds-color--primary);
  --background-color: var(--fds-color--white);
  --background-color--default: var(--fds-color--white);
  --background-color--success: var(--fds-color--white);
  --background-color--warning: var(--fds-color--white);
  --background-color--error: var(--fds-color--white);
  --padding: #{fds-rem(16px 32px)};
  --leading: #{fds-rem(24px)};
  --margin-left: none;
  --outline-offset--dismiss: #{fds-rem(6px)};
  --outline-offset--text: #{fds-rem(2px)};

  @include fds-set-brand(lincoln) {
    --icon-offset: #{fds-rem(10px)};
    --icon-symbol--default: none;
    --icon-symbol--success: var(--fds-font--ford-icons__tick);
    --text-color: var(--fds-color--primary);
    --text-color--default: var(--fds-color--gray2);
    --text-color--success: var(--fds-color--primary);
    --text-color--error: var(--fds-color--primary);
    --background-color: var(--fds-color--white);
    --background-color--default: var(--fds-color--primary);
    --background-color--success: #d6e9df; // WARNING: hardcoded background color
    --background-color--error: var(--fds-color--caution1);
    --padding: #{fds-rem(8px 20px)};
    --border-bottom: none;
    --shadow: none;
    --margin-left--icon: auto; // for centering
    --margin-left--content: auto;
  }

  position: absolute;

  &.fmc-top-banner--persistent {
    position: relative;
  }

  width: 100%;
  display: block;
  box-shadow: var(--shadow);
  background-color: var(--alert-color);
  overflow: clip;
  transition: max-height 0.3s ease-in-out;

  max-height: fds-rem(200px);
  @media (min-width: map-get($fds-breakpoints, 'fds-md')) {
    max-height: fds-rem(100px);
  }

  &[hidden] {
    max-height: 0;

    .fmc-top-banner__inner {
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
  }

  .fmc-top-banner__inner {
    width: 100%;
    border-bottom: var(--border-bottom);
    padding: var(--padding);
    line-height: var(--leading);
    background-color: var(--background-color);
    color: var(--text-color);
    font-size: var(--font-size);
    transition: transform 0.3s ease-in-out 0.1s;

    display: flex;
    align-items: flex-start;

    // Insert "standard" icon
    &::before {
      @extend %fds-icons;
      content: var(--icon-symbol);
      font-size: var(--tb-icon-size);
      line-height: inherit;
      color: var(--icon-color);
      margin-right: var(--icon-offset);
      margin-left: var(--margin-left--icon);
    }
  }


  &.fmc-top-banner--default {
    --icon-symbol: var(--icon-symbol--default);
    --text-color: var(--text-color--default);
    --background-color: var(--background-color--default);
  }

  &.fmc-top-banner--success {
    --alert-color: var(--fds-color--success1);
    --icon-symbol: var(--icon-symbol--success);
    --text-color: var(--text-color--success);
    --background-color: var(--background-color--success);
    --margin-left--content: none;
  }

  &.fmc-top-banner--warning {
    --alert-color: var(--fds-color--caution1);
    --icon-symbol: var(--icon-symbol--warning);
    --text-color: var(--text-color--warning);
    --background-color: var(--background-color--warning);
    --margin-left--content: none;
  }

  &.fmc-top-banner--error {
    --alert-color: var(--fds-color--error1);
    --icon-symbol: var(--icon-symbol--error);
    --text-color: var(--text-color--error);
    --background-color: var(--background-color--error);
    --margin-left--content: none;
  }

  // Custom icon
  .fmc-top-banner__custom-icon {
    line-height: inherit;
    color: var(--icon-color);
    margin-left: var(--margin-left--icon);
  }

  .fds-icon--offset-left,
  .fds-icon--offset-right {
    --offset: var(--icon-offset);
  }

  .fmc-top-banner__content {
    margin-right: auto;
    margin-left: var(--margin-left--content);
  }

  a {
    text-decoration: underline;

    &:focus-visible {
      outline: var(--fds-outline);
      outline-offset: var(--outline-offset--text);
    }
  }

  .fmc-top-banner__custom-icon + .fmc-top-banner__content {
    --margin-left--content: none;
  }

  .fmc-top-banner__chevron {
    @extend %fds-icons;
    margin: fds-rem(4px 0);
    margin-left: var(--icon-offset);

    &::before {
      content: var(--fds-font--ford-icons__chevron-right);
    }
  }

  .fmc-top-banner__dismiss {
    @extend %fds-icons;
    cursor: pointer;
    margin: 0 0 0 var(--icon-offset);

    --icon-size: var(--dismiss-icon-size);

    &::before {
      content: var(--fds-font--ford-icons__clear);
    }

    &:focus-visible {
      outline: var(--fds-outline);
      outline-offset: var(--outline-offset--dismiss);
    }
  }
}
