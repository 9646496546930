/*
 * Carousel Container
 */

.fmc-carousel-container {
  position: relative;

  --icon-font: 'FordIcons';
  --pancake-color: var(--fds-color--gray2);
  --pancake-color-active: var(--fds-color--primary);
  --pancake-width: 3.2rem;
  --pancake-height: 0.3rem;
  --pancake-height-active: 0.3rem;
  --directional-button-focus-outline-color: var(--fds-color--primary);
  --directional-button-width: 4rem;
  --directional-button-height: 4rem;
  --directional-button-position: relative;
  --directional-button-top: 0;
  --directional-button-gap: 0;
  --directional-button-border-radius: 0.3rem;
  --directional-button-box-shadow: var(--fmc-elevation__box-shadow--layer2);
  --directional-button-box-shadow-hover: var(--fmc-elevation__box-shadow--layer3);
  --directional-button-color: var(--fds-color--white);
  --directional-button-color-disabled: var(--fds-color--white);
  --directional-button-background-color: var(--fds-color--primary);
  --directional-button-background-color-hover: var(--fds-color--secondary);
  --directional-button-background-color-disabled: var(--fds-color--disabled3);
  --directional-button-border: none;
  --directional-button-border-disabled: none;

  // TODO: figure out why the variables are not working here
  // --directional-button-arrow-next: #{$fds-font--ford-icons__chevron-right};
  // --directional-button-arrow-previous: #{$fds-font--ford-icons__chevron-left};
  --directional-button-arrow-next: '\e90d';
  --directional-button-arrow-previous: '\e90c';
  --directional-button-size: 'inherit';

  @include fds-set-brand(lincoln) {
    --icon-font: 'LincolnIcons';
    --pancake-color: var(--fds-color--gray4);
    --pancake-color-active: var(--fds-color--secondary);
    --pancake-width: 3rem;
    --pancake-height: 0.1rem;
    --pancake-height-active: 0.2rem;
    --directional-button-border-radius: 50%;
    --directional-button-box-shadow: none;
    --directional-button-box-shadow-hover: none;
    --directional-button-color: var(--fds-color--primary);
    --directional-button-color-disabled: var(--fds-color--gray4);
    --directional-button-background-color: transparent;
    --directional-button-background-color-hover: transparent;
    --directional-button-background-color-disabled: transparent;
    --directional-button-border: 2px solid var(--fds-color--primary);
    --directional-button-border-disabled: 2px solid var(--fds-color--gray4);

    // TODO: figure out why the variables are not working here
    // --directional-button-arrow-next: #{$fds-font--ford-icons__long-arrow-forward};
    // --directional-button-arrow-previous: #{$fds-font--ford-icons__long-arrow-back};
    --directional-button-arrow-next: '\e919';
    --directional-button-arrow-previous: '\e918';
    --directional-button-size: 1.8rem;
  }
}

.fmc-carousel-container--inline-directional-buttons {
  --directional-button-position: absolute;
  --directional-button-top: calc(50% - var(--directional-button-height));
  --directional-button-gap: 3rem;

  @include fds-set-brand(lincoln) {
    --directional-button-focus-outline-color: var(--fds-color--white);
    --directional-button-width: 7rem;
    --directional-button-height: 7rem;
    --directional-button-color: var(--fds-color--primary);
    --directional-button-color-disabled: var(--fds-color--primary);
    --directional-button-background-color: var(--fds-color--white);
    --directional-button-background-color-hover: var(--fds-color--white-80);
    --directional-button-background-color-disabled: var(--fds-color--gray4);
    --directional-button-border: none;
    --directional-button-size: 1.6rem;
  }
}

/*
 * Carousel
 */

.fmc-carousel {
  position: relative;
  display: flex;
}

.fmc-carousel__image {
  height: auto;
  width: 100%;
  display: block;
}

/*
 * Carousel Indicator
 */

.fmc-carousel-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fmc-carousel-indicator__directional-button {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  position: var(--directional-button-position);
  top: var(--directional-button-top);
  width: var(--directional-button-width);
  height: var(--directional-button-height);

  // remove flash on iOS mobile as accessibility indicators are customized in this CSS
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::before {
    content: '';
    border-radius: var(--directional-button-border-radius);
    background-color: var(--directional-button-background-color);
    box-shadow: var(--directional-button-box-shadow);
    border: var(--directional-button-border);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    will-change: transform, background-color;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }

  &:not(.fmc-carousel-indicator__button--disabled) {
    &:hover::before,
    &:active::before,
    &.fmc-carousel-indicator__button--active::before {
      background-color: var(--directional-button-background-color-hover);
    }

    &:hover::before {
      transform: scale(1.07);
      box-shadow: var(--directional-button-box-shadow-hover);
    }

    &:active::before,
    &.fmc-carousel-indicator__button--active::before {
      transform: scale(1);
      box-shadow: $fds-elevation__box-shadow--layer1;
    }
  }

  &.fmc-carousel-indicator__button--disabled {
    cursor: not-allowed;

    &::before {
      background-color: var(--directional-button-background-color-disabled);
      box-shadow: none;
      border: var(--directional-button-border-disabled);
    }

    &::after {
      color: var(--directional-button-color-disabled);
    }
  }

  &::after {
    color: var(--directional-button-color);
    position: relative;
    text-align: center;
    width: 100%;
    pointer-events: none; // Fix for IE11 :active not firing on link click
    font-family: var(--icon-font) !important;
    font-size: var(--directional-button-size);
  }

  &.fmc-carousel-indicator__directional-button--previous {
    left: var(--directional-button-gap);
  }

  &.fmc-carousel-indicator__directional-button--next {
    right: var(--directional-button-gap);
  }

  &.fmc-carousel-indicator__directional-button--next::after {
    content: var(--directional-button-arrow-next);
  }

  &.fmc-carousel-indicator__directional-button--previous::after {
    content: var(--directional-button-arrow-previous);
  }
}

.fmc-carousel-indicator__pagination {
  align-self: center;
}

.fmc-carousel-indicator__pagination-text {
  @extend %fmc-type--body1;

  margin: fds-rem(auto 24px);
  display: flex;
  align-items: center;
}

.fmc-carousel-indicator__button--active {
  & .fmc-carousel-indicator__pancake {
    height: var(--pancake-height-active);
    background-color: var(--pancake-color-active);
  }
}

.fmc-carousel-indicator__pancake-buttons {
  display: flex;
  list-style: none;
  padding: fds-rem(0 20px);
  line-height: 0;
}

.fmc-carousel-indicator__pancake-button {
  padding: fds-rem(10px 0);
}

.fmc-carousel-indicator__pancake {
  border-radius: fds-rem(2px);
  display: block;
  width: var(--pancake-width);
  height: var(--pancake-height);
  background-color: var(--pancake-color);
  margin: fds-rem(0 4px);
}

/*
 * Focus Styles
 */

.fmc-carousel-indicator__directional-button:not(.fmc-carousel-indicator__button--disabled):focus-visible,
.fmc-carousel-indicator__pancake-button:focus-visible,
.fmc-carousel-indicator__pagination-text:focus-visible {
  outline: 1px solid var(--fds-color--primary);
}

.fmc-carousel-indicator__directional-button:not(.fmc-carousel-indicator__button--disabled):focus-visible {
  outline-offset: fds-rem(5px);
}

.fmc-carousel-indicator__pancake-button:focus-visible {
  outline-offset: fds-rem(1px);
}

.fmc-carousel-indicator__pagination-text:focus-visible {
  outline-offset: fds-rem(10px);
}

.fmc-carousel-container--inline-directional-buttons .fmc-carousel-indicator__directional-button:not(.fmc-carousel-indicator__button--disabled):focus-visible {
  outline: 1px solid var(--directional-button-focus-outline-color);
}
