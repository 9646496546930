.fmc-segmented-control {
  --font-size: #{fds-rem(18px)};
  --height: #{fds-rem(40px)};
  --border-color: var(--fds-color--gray2);
  --border-radius: #{fds-rem(999px)}; // for pill shape regardless of dimensions: https://www.w3.org/TR/css-backgrounds-3/#corner-overlap
  --button-padding: #{fds-rem(0 24px)};
  --button-border-right: 1px solid #d1d1d1;
  --slider-padding: #{fds-rem(0 32px)};
  --box-shadow: var(--fmc-elevation__box-shadow--layer2);

  display: block;
  text-align: center;
  overflow: hidden;
  padding-top: fds-rem(8px);

  @include fds-set-brand(lincoln) {
    --font-size: #{fds-rem(16px)};
    --height: #{fds-rem(50px)};
    --border-color: var(--fds-color--disabled2);
    --border-radius: 0;
    --button-padding: #{fds-rem(0 20px)};
    --button-border-right: none;
    --slider-padding: #{fds-rem(0 20px)};
    --box-shadow: none;
  }
}

// Active
.fmc-segmented-control__button--active {
  & .fmc-segmented-control__button::before {
    transform: scale(1);
  }
}

// Focus
.fmc-segmented-control__button:focus-visible {
  outline-offset: fds-rem(16px);
  outline: 1px solid var(--fds-color--primary);
}

.fmc-segmented-control__controls {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--height);
  position: relative;
}

.fmc-segmented-control__buttons {
  position: relative;
  height: calc(var(--height) - #{fds-rem(4px)});
  display: inline-flex;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  padding: fds-rem(8px);
  border-color: var(--border-color);
}

.fmc-segmented-control__button {
  display: inline-flex;
  padding: var(--button-padding);
  text-decoration: none;
  background: none;
  border: none;
  border-right: var(--button-border-right);

  // remove flash on iOS mobile as accessibility indicators are customized in this CSS
  -webkit-tap-highlight-color: var(--fds-color--tertiary-0);

  // prevent button elements from shifting in IE11
  top: 0;
  left: 0;

  &:last-child {
    border-right: none;
  }
}

.fmc-segmented-control__button-text {
  @extend %fmc-type--body1;

  color: var(--fds-color--primary);
  white-space: nowrap;
  font-size: var(--font-size);
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.fmc-segmented-control__slider {
  @extend %fmc-type--body1;

  color: var(--fds-color--white);
  height: var(--height);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: var(--slider-padding);
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  white-space: nowrap;
  box-shadow: var(--box-shadow);
  transition: 0.3s ease-in-out;
  border-color: var(--fds-color--primary);
  background-color: var(--fds-color--primary);
}

.fmc-segmented-control__panels {
  text-align: left;
}

.fmc-segmented-control__panel {
  display: none;

  &.fmc-segmented-control__panel--show {
    display: block;
  }
}

.fmc-segmented-control--mobile {
  & .fmc-segmented-control__controls {
    display: block;
  }

  & .fmc-segmented-control__buttons {
    left: 0;
    height: 100%;
    transition: left 0.3s ease-in-out;
  }

  & .fmc-segmented-control__slider {
    left: 50%;
    transform: translateX(-50%);
  }
}
