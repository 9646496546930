.fmc-gallery {
  margin-bottom: fds-rem(4px);
  padding: 0;

  .fds-layout-grid__inner {
    grid-auto-flow: dense;
    grid-gap: fds-rem(4px);
  }

  .fmc-gallery__media {
    grid-row: span 2;
    margin: 0;
    overflow: hidden;
    position: relative;
  }

  .fmc-gallery__media:focus {
    outline: none;
  }

  .fmc-gallery__media:focus-visible {
    outline: var(--fds-outline);
  }

  .fds-layout-grid__cell--span-4 {
    grid-row: span 1;
  }

  .fmc-gallery__img {
    display: block;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    width: 100%;
  }

  .fmc-gallery__caption {
    background-color: var(--fds-color--primary-90);
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    width: 100%;
  }

  .fmc-gallery__title {
    @extend %fmc-type--body2;
    color: var(--fds-color--white);
    padding: fds-rem(24px) fds-rem(16px);
  }

  .fmc-gallery__media:hover .fmc-gallery__img,
  .fmc-gallery__media:focus-visible .fmc-gallery__img {
    transform: scale(1.07);
  }

  .fmc-gallery__media:hover .fmc-gallery__caption,
  .fmc-gallery__media:focus-visible .fmc-gallery__caption {
    transform: translateY(0);
  }

  @include media('>=fds-md') {
    .fmc-gallery__media--stack {
      grid-row: span 1;
    }

    .fmc-gallery__media--first-column {
      grid-column-start: 1
    }
  }
}
